import React from 'react';
import '../App.css'; // Importing App.css for styling
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { Container, Row, Col } from 'react-bootstrap';

function Contact() {
  return (
    <Container fluid>
      <Row className="justify-content-center">
        <Col xs={12} md={10} lg={8}>
          <article className="mb-4">
            <h1>Kontakta oss</h1>
            <p>Onytec AB är ett företag som är passionerat för att göra elbilsladdning enklare och mer kostnadseffektivt. Vi strävar efter att ge våra kunder den bästa möjliga upplevelsen.</p>
            <p>Välkommen att kontakta oss om du har frågor om appen, förslag på förbättringar eller andra funderingar.</p>
          </article>  
          <article>
            <h4>Kontaktinformation</h4>
            <p><strong>Adress:</strong> Fagerövägen 27A</p>
            <p><strong>Organisationsnummer:</strong> 559416-7511</p>
            <p>
              <strong>Email:</strong> info@laddvis.se 
              <a href="#" onClick={() => navigator.clipboard.writeText('info@laddvis.se')} className="ms-2">
                <FontAwesomeIcon icon={faCopy} style={{ color: 'orange' }} />
              </a>
            </p>
          </article>
        </Col>
      </Row>
    </Container>
  );
}

export default Contact;
