import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { Link } from 'react-router-dom';
import config from '../config';

function VerifyEmail() {
  const query = new URLSearchParams(useLocation().search);
  const email = query.get('email');
  const token = query.get('token');
  const [message, setMessage] = useState('');

  useEffect(() => {
    
    axios.post(config.API_ENDPOINT + '/User/confirm', { email: email, token: token })
      .then(response => {
        if (response.status === 200) {
          setMessage('Din e-post är bekräftad!');
        } else {
          setMessage('Tyvärr kunde din e-post inte bekräftas. Försök igen.');
        }
      })
      .catch(() => {
        setMessage('Tyvärr kunde din e-post inte bekräftas. Försök igen.');
      });
  }, [email, token]);

  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col md="auto">
          <h1>Bekräfta e-post</h1>
          <h2>{message}</h2>
          {message === 'Din e-post är bekräftad!' && <Link to="/">Gå till startsidan Laddvis.se</Link>}
        </Col>
      </Row>
    </Container>
  );
}

export default VerifyEmail;

