
//Dev config
const dev = {
    API_ENDPOINT: 'http://localhost:5104/api'
};

    
//Prod config
const prod = {
    API_ENDPOINT:  'https://laddvisapi.azurewebsites.net/api'

};

//Change between dev and prod as needed
const config = prod;

export default config;