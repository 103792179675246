import React from 'react';
import { Accordion } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';

function HowItWorks() {

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }}>
      <div style={{ flex: 1 }}></div> {/* Left dynamic column */}
      <div style={{ maxWidth: '1000px', alignItems: 'flex-start' }}>
        <article style={{ textAlign: 'left' }}>
          <h1>Hur det fungerar</h1>
          <p>Laddvis-appen laddar du hem som vanligt till din Android-mobil i Googles Play Store. </p>          
          <p>Laddvis förutsätter att du har ett elavtal med timpriser. Med ett sådan avtal så varierar priset för din el för varje timme. Ibland är det biligare och ibland är det dyrare och priserna görs tillgängliga i förväg för kommande dygn. Genom att räkna ut hur många kWH din bil behöver laddas med, vilken effekt du vill ha på laddningen och timpriserna kan vi se till så att laddningen sker när det är som allra billigast.

          </p>
          <p>Inför varje ny laddning behöver du bara ange bilens batterinivå och när du vill att laddningen ska vara klar så sköter appen resten!</p>
        </article>
        <br />
        
        <article style={{ textAlign: 'left' }}>
          <h1>Kom igång</h1>
          <p>Första steget för att komma igång är såklart att ladda hem appan via Google Play Store.</p>          
          <p>Efter att du installerat appen följer du bara guiden för att komma igång. Guiden hjälper dig med att ansluta till din laddbox, att ange elområde och att ställa in uppgifter om din bil så som batterikapacitet.</p>
        </article>
        
        <br />

        <article style={{ textAlign: 'left' }}>
          <h1>Kom igång</h1>
          <p>Första steget för att komma igång är såklart att ladda hem appan via Google Play Store.</p>          
          <p>Efter att du installerat appen följer du bara guiden för att komma igång. Guiden hjälper dig med att ansluta till din laddbox, att ange elområde och att ställa in uppgifter om din bil så som batterikapacitet.</p>
        </article>
              
        <br />

        <article style={{ textAlign: 'left' }}>
          <h1>Förutsättningar</h1>
          <p>Laddvis finns för Android i dagsläget. En IOS-version för IPhone är på gång.</p>          
          <p>Easee Laddbox krävs. Har du en annan laddbox och du vill använda appen så vill vi gärna att du kontaktar oss.</p>
          <p>Timprisavtal med din elleverantör.</p>
        </article>
              
        <br />

        <article style={{ textAlign: 'left' }}>
          <h2>Vanliga frågor</h2>
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Vilka mobiler fungerar appen på?</Accordion.Header>
              <Accordion.Body>
                I dagsläget stödjer vi Android. Stöd för IOS kommer snart.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Vilka laddboxar har ni stöd för?</Accordion.Header>
              <Accordion.Body>
                Easees laddboxar. Vi jobbar på att lägga till stöd för fler. Om du vill använda appen med en annan laddbox så maila oss gärna på info@laddvis.se och berätta vilken.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>Vilket elavtal ska jag ha för att kunna använda appen?</Accordion.Header>
              <Accordion.Body>
                Du behöver ha ett timprisavtal för att kunna dra nytta av appen. Det innebär att du får ett pris för varje timme. Det finns flera el-leverantörer på marknaden som erbjuder den typen av avtal.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>Vad kostar det?</Accordion.Header>
              <Accordion.Body>
                Första 30 dagarna är gratis. Därefter kostar appen 29kr i månaden.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>Vilka bilmodeller stöds i appen?</Accordion.Header>
              <Accordion.Body>
                Alla bilmodeller som kan laddas med Easee-laddare stöds. Du anger bara själv bilens batterikapacitet och laddningseffekt en gång i inställningarna. Samt att du inför varje laddning anger aktuell laddningsnivå i batteriet.          
              </Accordion.Body>
            </Accordion.Item>      
          </Accordion>
        </article>
      </div>
      <div style={{ flex: 1 }}></div> {/* Right dynamic column */}
    </div>
  );
}

export default HowItWorks;
