import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';

import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { Link } from 'react-router-dom';
import config from '../config';

function ResetPassword() {
  const query = new URLSearchParams(useLocation().search);
  const resetToken = query.get('resettoken');
  const email = query.get('email');
  const [message, setMessage] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formDisabled, setFormDisabled] = useState(false);

  const passwordValidation = (password) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&_])[A-Za-z\d@$!%*?&_]{8,}$/;
    return regex.test(password);
  };

  const handleSubmit = () => {
    if(newPassword === confirmPassword && passwordValidation(newPassword)) {
      setIsSubmitting(true);
      axios.post(config.API_ENDPOINT + '/User/setnewpassword', { email: email, token: resetToken, newPassword: newPassword })
        .then(response => {
          if (response.status === 200) {
            setMessage('Ditt lösenord har ändrats!');
            setFormDisabled(true);
          } else {
            setMessage('Tyvärr kunde ditt lösenord inte ändras. Försök igen.');
          }
        })
        .catch((error) => {
          setMessage(JSON.stringify({ email: email, token: resetToken, newPassword: newPassword }));
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    } else {
      setMessage('Lösenorden matchar inte eller uppfyller inte kraven.');
    }
  };

  return (
    <Container fluid>
      <Row className="justify-content-center">
        <Col xs={12} sm={10} md={8} lg={6}>
          <article className="mt-4">
            <h1 className="mb-4">Välj nytt lösenord</h1>
            <div className="mb-3">
              <label className="form-label">E-post</label>
              <p>{email}</p>
            </div>
            {!formDisabled && (
              <>
                <div className="mb-3">
                  <label htmlFor="newPassword" className="form-label">Nytt lösenord</label>
                  <input
                    type="password"
                    id="newPassword"
                    className="form-control"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    disabled={formDisabled}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="confirmPassword" className="form-label">Upprepa lösenord</label>
                  <input
                    type="password"
                    id="confirmPassword"
                    className="form-control"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    disabled={formDisabled}
                  />
                </div>
                <div className="mb-3">
                  <button
                    className="btn btn-primary"
                    onClick={handleSubmit}
                    disabled={isSubmitting || formDisabled || !newPassword || !confirmPassword}
                  >
                    Ändra lösenord
                  </button>
                </div>
              </>
            )}
            <p className="small text-muted">Lösenordet måste vara minst 8 tecken långt och innehålla minst en versal, en gemen, en siffra och ett specialtecken.</p>
            {message && (
              <div className={`alert ${message === 'Ditt lösenord har ändrats!' ? 'alert-success' : 'alert-danger'}`}>
                <p>{message}</p>
                {message === 'Ditt lösenord har ändrats!' && (
                  <Link to="/" className="btn btn-link">Gå till Laddvis.se</Link>
                )}
              </div>
            )}
          </article>
        </Col>
      </Row>
    </Container>
  );
}

export default ResetPassword;
